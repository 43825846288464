var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"padding-top":"6rem","z-index":"1"}},[_vm._l((_vm.download_urls),function(download_url,i){return _c('iframe',{staticClass:"d-none",attrs:{"src":download_url,"frameborder":"0",":key":i}})}),_vm._l((_vm.messages),function(message){return _c('v-row',{key:message.created_at,staticClass:"d-flex no-gutters",class:{ all_right: message.mine },staticStyle:{"background-color":"transparent"}},[_c('v-col',{staticClass:"col-12 px-1",class:{
            'center d-flex': !message.mine  && ( message.user_id == 1), 
            'right': message.mine, 
            'left': !message.mine  && message.user_id != 1, 
            }},[((message.type == 'text'))?_c('span',{staticClass:"pa-1 rounded",class:{
                    'grey darken-2 pa-0 white--text': message.user_id == 1,
                    'blue-ish-message-box': message.mine, 
                    'white-message-box': !message.mine  && message.user_id != 1, 
                }},[_vm._v(" "+_vm._s(message.message)+" ")]):_c('span',{staticClass:"white-message-box pa-1 rounded"},[(!_vm.isImage(message.message))?_c('v-icon',{staticClass:"pa-2 green--text",attrs:{"small":""}},[_vm._v("mdi-file")]):_vm._e(),(_vm.isImage(message.message))?_c('v-icon',{staticClass:"pa-2 green--text",attrs:{"small":""}},[_vm._v("mdi-image")]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("refineFileNameMessage")(message.message))+" "),_c('v-icon',{staticClass:"pa-1 white--text green rounded",attrs:{"x-small":""},on:{"click":function($event){return _vm.downloadFile(message)}}},[_vm._v("mdi-arrow-collapse-down")])],1)])],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }